import { useEffect, useState } from 'react';

export function useTextWidth(text: string) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const span = document.createElement('span');
    span.innerText = text;
    document.body.appendChild(span);
    const width = span.offsetWidth;
    document.body.removeChild(span);
    setWidth(width);
  }, [text]);

  return width;
}
