import React, { FC } from 'react';
import styles from './DayItem.module.scss';
import { DateTime } from 'luxon';
import classNames from 'classnames';

interface Props {
  percent: number;
  featureCount?: number;
  notificationCount?: number;
  dateTime: DateTime;
  isAfterToday: boolean;
}

const DayItem: FC<Props> = ({ dateTime, percent = 0, isAfterToday, featureCount = 0, notificationCount = 0 }) => {
  return (
    <div
      className={classNames(styles.dayItem, isAfterToday && styles.isAfterToday)}
      style={{ background: `rgba(58, 227, 116, ${percent})` }}
    >
      <span className={styles.tooltip}>
        <span className={styles.date}>{dateTime.toFormat('yyyy.MM.dd')}</span>
        <span className={styles.count}>{featureCount} features</span>
        <span className={styles.count}>{notificationCount} notifications</span>
      </span>
    </div>
  );
};

export default DayItem;
