import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Notification } from '../declaration/graphql';
import { client } from '../cores/client';
import { notificationsQuery } from '../queries/notification';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';
import { uniqBy } from 'lodash';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

const pageSize = 20;

export enum NotificationTypes {
  getNotifications = '@notification/getNotifications'
}

export interface NotificationState {
  stopPagination: boolean;
  notifications: Array<Notification>;
}

export const NotificationActions = {
  getNotifications: createAsyncSagaAction(NotificationTypes.getNotifications, (page: number) => {
    return client.query({ query: notificationsQuery, variables: { attributes: { skip: (page - 1) * pageSize, take: pageSize } }, fetchPolicy: 'no-cache' });
  })
};

const initialState: NotificationState = {
  stopPagination: false,
  notifications: []
};

export default handleActions<NotificationState, any>(
  {
    ...createAsyncSagaReducerMap(NotificationTypes.getNotifications, {
      onSuccess: (state, action: PayloadAction<string, { data: { notifications: Array<Notification> } }>) => {
        return produce(state, draft => {
          const notifications = action.payload.data.notifications;

          if (notifications.length === 0) {
            draft.stopPagination = true;
            return;
          }

          draft.notifications = uniqBy([...state.notifications, ...notifications], 'id');
        });
      }
    })
  },
  initialState
);
