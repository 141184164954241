import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './IssueForm.module.scss';
import Typography from '../Typography/Typography';
import TextInput from '../TextInput/TextInput';
import { useFormik } from 'formik';
import Textarea from '../Textarea/Textarea';
import Button from '../Button/Button';
import { get, trim } from 'lodash';
import { useToast } from '../../hooks/useToast';
import { useDispatch } from 'react-redux';
import { IssueActions, IssueTypes } from '../../store/issue';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import classNames from 'classnames';

interface Props {
  onClose: () => void
}

interface IssueFormValues {
  summary: string;
  description: string;
}

const IssueForm: FC<Props> = ({ onClose }) => {
  const [isSuspend, setSuspend] = useState(false);
  const isCreated = useRef<boolean>();
  const isLoading = useTypedSelector(({ loading: { asyncMap } }) => get(asyncMap, IssueTypes.createIssue) > 0);

  const toast = useToast();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: IssueFormValues) => {
      if (isSuspend) {
        toast.error('이슈가 생성중입니다. 잠시만 기다려주세요.');
        return;
      }

      if (trim(values.summary) === '') {
        toast.error('제목을 입력해주세요.');
        return;
      }

      setSuspend(true);
      dispatch(IssueActions.createIssue(values.summary, values.description));
    },
    [isSuspend, dispatch, toast]
  );

  const { values, handleChange, handleSubmit } = useFormik<IssueFormValues>({
    initialValues: {
      summary: '',
      description: ''
    },
    onSubmit
  });

  useEffect(() => {
    if (isSuspend && isCreated.current) {
      onClose();
      toast.success('새로운 이슈가 생성되었습니다.');
    } else if (isSuspend && isLoading) {
      isCreated.current = true;
    }
  }, [isSuspend, isLoading, onClose, toast]);

  return (
    <div className={styles.issueForm}>
      <form onSubmit={handleSubmit} className={classNames(styles.inner, isLoading && styles.isLoading)}>
        <Typography variant="h2" className={styles.title}>
          New Issue
        </Typography>
        <TextInput
          className={styles.summary}
          name="summary"
          placeholder="Summary"
          value={values.summary}
          onChange={handleChange}
        />
        <Textarea
          className={styles.description}
          name="description"
          placeholder="Description"
          value={values.description}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button variant="danger" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </div>
      </form>
    </div>
  );
};

export default IssueForm;
