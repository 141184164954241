import { useEffect } from 'react';

export function usePagination(callback: () => void) {
  useEffect(() => {
    function onScroll() {
      if (document.body.scrollHeight - window.innerHeight * 0.1 <= window.scrollY + window.innerHeight) {
        callback();
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [callback]);
}
