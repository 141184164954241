import React, { FC, FormEvent } from 'react';
import styles from './Textarea.module.scss';
import classNames from 'classnames';
import { useFocus } from '../../hooks/useFocus';
import { CSSTransition } from 'react-transition-group';

interface Props {
  className?: string;
  name?: string;
  value: string;
  placeholder?: string;
  onChange: (e: FormEvent<HTMLTextAreaElement>) => void;
}

const Textarea: FC<Props> = ({ className, placeholder = '', name, value, onChange }) => {
  const [isFocus, onFocus, onBlur] = useFocus();
  const isDisplayPlaceholder = value === '' && placeholder !== '';

  return (
    <div className={classNames(styles.textInput, isFocus && styles.isFocus, className)}>
      <div className={styles.input}>
        <CSSTransition in={isDisplayPlaceholder} timeout={300} classNames="" mountOnEnter unmountOnExit>
          <span className={styles.placeholder}>{placeholder}</span>
        </CSSTransition>
        <textarea name={name} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
      </div>
    </div>
  );
};

export default Textarea;
