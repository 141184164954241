import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import LoginForm from '../LoginForm/LoginForm';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Route, Switch } from 'react-router';
import Dashboard from '../Dashboard/Dashboard';
import Layout from '../Layout/Layout';
import Notifications from '../Notifications/Notifications';
import Features from '../Features/Features';
import Issues from '../Issues/Issues';

interface Props {}

const App: FC<Props> = memo(() => {
  const { token } = useTypedSelector(({ user: { token } }) => ({ token }));
  const isAuthenticated = token !== null;

  return (
    <div className={styles.app}>
      {isAuthenticated ? (
        <Layout>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/issues" component={Issues} />
            <Route path="/features" component={Features} />
          </Switch>
        </Layout>
      ) : (
        <LoginForm />
      )}
    </div>
  );
});

export default App;
