import produce from 'immer';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { client } from '../cores/client';
import { loginQuery } from '../queries/user';
import { action, PayloadAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';

export enum UserTypes {
  login = '@user/login',
  logout = '@user/logout',
}

export interface UserState {
  token: string | null;
}

export const UserActions = {
  login: createAsyncSagaAction(UserTypes.login, (username: string, password: string) =>
    client.query({ query: loginQuery, variables: { username, password } })
  ),
  logout: () => action(UserTypes.logout)
};

const initialState: UserState = {
  token: localStorage.getItem('token') || null
};

export default handleActions<UserState, any>(
  {
    [UserTypes.logout]: (state) => {
      return produce(state, draft => {
        draft.token = null;
        localStorage.removeItem('token')
      });
    },
    ...createAsyncSagaReducerMap(UserTypes.login, {
      onSuccess: (state, action: PayloadAction<string, { data: { login: string | null } }>) => {
        const token = action.payload.data.login;

        if (token !== null) {
          return produce(state, draft => {
            draft.token = token;
            localStorage.setItem('token', token);
          });
        }
        return state;
      }
    })
  },
  initialState
);
