import React, { FC, useMemo } from 'react';
import styles from './IssueItem.module.scss';
import { Issue } from '../../declaration/graphql';
import classNames from 'classnames';
import Card from '../Card/Card';

interface Props {
  issue: Issue;
  zIndex: number;
}

const IssueItem: FC<Props> = ({ issue: { key, summary, description, deletedAt, createdAt, inReview, completedAt }, zIndex }) => {
  const isCompleted = useMemo(() => {
    return completedAt !== null;
  }, [completedAt]);

  return (
    <Card style={{ zIndex }} className={classNames(styles.issueItem, isCompleted && styles.isCompleted)}>
      <div className={styles.content}>
        <h1 className={styles.key}>{key}</h1>
        <div className={styles.summary}>{summary}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles.tags}>
        {inReview && <span className={styles.inReview}>리뷰필요</span>}
        {isCompleted && <span className={styles.completed}>완료</span>}
      </div>
    </Card>
  );
};

export default IssueItem;
