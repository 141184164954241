import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Issue } from '../declaration/graphql';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { DateTime } from 'luxon';
import { client } from '../cores/client';
import { issuesQuery, createIssueQuery } from '../queries/issue';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';

export enum IssueTypes {
  getIssues = '@issues/getIssues',
  createIssue = '@issues/createIssue'
}

export interface IssueState {
  count: number;
  issues: Array<Issue>;
}

export const IssueActions = {
  getIssues: createAsyncSagaAction(IssueTypes.getIssues, (startAt: DateTime, endAt: DateTime) => {
    return client.query({
      query: issuesQuery,
      fetchPolicy: 'no-cache',
      variables: { attributes: { startAt: startAt.toISO(), endAt: endAt.toISO() } }
    });
  }),
  createIssue: createAsyncSagaAction(IssueTypes.createIssue, (summary: string, description: string) => {
    return client.mutate({
      mutation: createIssueQuery,
      fetchPolicy: 'no-cache',
      variables: { data: { summary, description } }
    });
  })
};

const initialState: IssueState = {
  count: 0,
  issues: []
};

export default handleActions<IssueState, any>(
  {
    ...createAsyncSagaReducerMap(IssueTypes.getIssues, {
      onSuccess: (state, action: PayloadAction<string, { data: { issues: { total: number; results: Array<Issue> } } }>) => {
        return produce(state, draft => {
          draft.count = action.payload.data.issues.total;
          draft.issues = action.payload.data.issues.results;
        });
      }
    }),
    ...createAsyncSagaReducerMap(IssueTypes.createIssue, {
      onSuccess: (state, action: PayloadAction<string, { data: { createIssue: Issue } }>) => {
        return produce(state, draft => {
          draft.issues.push(action.payload.data.createIssue);
        });
      }
    })
  },
  initialState
);
