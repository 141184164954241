import React, { CSSProperties, FC } from 'react';
import styles from './Card.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  style?: CSSProperties;
}

const Card: FC<Props> = ({ style, children, className }) => {
  return (
    <div style={style} className={classNames(styles.card, className)}>{children}</div>
  );
};

export default Card;
