import React, { FC, memo, useEffect } from 'react';
import styles from './Features.module.scss';
import Typography from '../Typography/Typography';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { FeatureActions } from '../../store/feature';
import { get, map } from 'lodash';
import FeatureItem from '../FeatureItem/FeatureItem';

interface Props {}

const Features: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const { features, deleteFeatureMap } = useTypedSelector(({ feature: { features, deleteFeatureMap } }) => {
    return {
      features,
      deleteFeatureMap
    };
  });

  useEffect(() => {
    dispatch(FeatureActions.getFeatures());
  }, [dispatch]);

  return (
    <div className={styles.features}>
      <Typography variant="h2" className={styles.title}>
        Features
      </Typography>
      <div>
        {map(features, feature => {
          return <FeatureItem key={feature.id} {...feature} isDisabled={get(deleteFeatureMap, feature.id) || false} />;
        })}
        {features.length === 0 && (
          <Typography variant="p" className={styles.empty}>
            No new <b>Features</b>
          </Typography>
        )}
      </div>
    </div>
  );
});

export default Features;
