import React, { FC } from 'react';
import styles from './NotificationItem.module.scss';
import { Notification } from '../../declaration/graphql';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { get } from 'lodash';
import Card from '../Card/Card';

interface Props {
  prevNotificationSize?: number;
}

const NotificationItem: FC<Props & Notification> = ({
  size,
  branch,
  commit,
  message,
  createdAt,
  repository,
  prevNotificationSize
}) => {
  const date = DateTime.fromISO(createdAt).toFormat('yyyy.MM.dd HH:mm:ss');
  const diff =
    typeof size === 'number' && typeof prevNotificationSize === 'number' ? (1 - size / prevNotificationSize) * 100 : null;

  const repositoryName = get(repository.match(/https:\/\/github\.com\/\w+\/(.*)/), 1);

  return (
    <Card className={classNames(styles.notificationItem)}>
      <div className={styles.information}>
        <div className={styles.title}>
          <Typography
            variant="p"
            className={classNames(
              styles.branch,
              branch === 'master' && styles.isMaster,
              branch === 'develop' && styles.isDevelop
            )}
          >
            {branch}
          </Typography>
          <Typography variant="p" className={styles.repository}>
            {repositoryName}
          </Typography>
        </div>
        <Typography variant="p" className={styles.date}>
          {date}
        </Typography>
      </div>
      <Typography variant="p" className={styles.commit}>
        {commit.substring(0, 7)}
      </Typography>
      <div className={classNames(styles.diff, typeof diff === 'number' ? (diff > 0 ? styles.isDown : styles.isUp) : null)}>
        {size && <span className={styles.size}>{size}KB</span>}
        {typeof diff === 'number' && (
          <React.Fragment>
            ({diff > 0 ? <FiTrendingDown /> : <FiTrendingUp />}
            {Math.abs(diff).toFixed(3)}%)
          </React.Fragment>
        )}
      </div>
      <Typography variant="p" className={styles.message}>
        {message}
      </Typography>
    </Card>
  );
};

export default NotificationItem;
