import { Issue } from '../declaration/graphql';
import { get, trim, findIndex } from 'lodash';

interface Group {
  name: string;
  values: Array<string>;
}

const summaryGroupNameRegex = /(\[[^\]]*])\s?/;

export function serializeIssues(issues: Array<Issue>) {
  const groups: Array<Group> = [];

  for (let i = 0; i < issues.length; i++) {
    const { key, summary, completedAt } = issues[i];

    if (summaryGroupNameRegex.test(summary)) {
      const isCompleted = completedAt !== null;
      const groupName = get(summary.match(summaryGroupNameRegex), 0) || '[Unknown]';
      const groupValue = `${key} ${summary.replace(summaryGroupNameRegex, '')} ${isCompleted ? `(완료)` : `(진행중)`}`;

      const findGroupIndex = findIndex(groups, ({ name }) => trim(name) === trim(groupName));

      if (findGroupIndex === -1) {
        groups.push({ name: groupName, values: [groupValue] });
      } else {
        groups[findGroupIndex].values.push(groupValue);
      }
    }
  }

  return groups;
}
