import React, { FC, useCallback } from 'react';
import styles from './FeatureItem.module.scss';
import { Feature } from '../../declaration/graphql';
import Typography from '../Typography/Typography';
import { get } from 'lodash';
import Button from '../Button/Button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FeatureActions } from '../../store/feature';
import { DateTime } from 'luxon';
import Card from '../Card/Card';

interface Props {
  isDisabled: boolean;
}

const FeatureItem: FC<Props & Feature> = ({ id, name, createdAt, isDisabled }) => {
  const matches = name.match(/feature\/(.*)/);
  const directory = get(matches, 1);
  const dispatch = useDispatch();
  const date = DateTime.fromISO(createdAt).toFormat('yyyy.MM.dd HH:mm:ss');

  const onDelete = useCallback(() => {
    dispatch(FeatureActions.deleteFeature(id));
  }, [dispatch, id]);

  return (
    <Card className={classNames(styles.featureItem, isDisabled && styles.isDisabled)}>
      <Typography variant="h3">{name}</Typography>
      <div className={styles.actions}>
        {directory && (
          <a href={`http://${directory.toLowerCase()}.feature.heydealer.com`} target="_blank" rel="noopener noreferrer">
            View
          </a>
        )}
        <Button variant="danger" onClick={onDelete} className={styles.delete}>
          Delete
        </Button>
      </div>
      <Typography variant="p" className={styles.date}>
        {date}
      </Typography>
    </Card>
  );
};

export default FeatureItem;
