import { all, put, take } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { FeatureActions } from '../store/feature';
import { ToastActions } from '../store/toast';
import { ToastVariant } from '../declaration/toast';
import { get } from 'lodash';

export default function*() {
  yield all([...createAsyncSaga(FeatureActions), watchDeleteFeature()]);
}

function* watchDeleteFeature() {
  while (true) {
    const response = yield take(FeatureActions.deleteFeature.success);
    if (typeof get(response, ['payload', 'data', 'deleteFeature']) === 'number') {
      yield put(ToastActions.createToast({ variant: ToastVariant.Success, message: '삭제되었습니다.' }));
    }
  }
}
