import { gql } from 'apollo-boost';

export const notificationsQuery = gql`
  query Notifications($attributes: NotificationsAttributes) {
    notifications(attributes: $attributes) {
      id
      size
      commit
      branch
      message
      createdAt
      repository
      authorName
    }
  }
`;
