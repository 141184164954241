import React, { FC } from 'react';
import styles from './Sidebar.module.scss';
import SidebarItem from '../SidebarItem/SidebarItem';
import { map, chain } from 'lodash';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';

interface Props {}

const sidebarItems = [
  { pathname: '/', label: 'Dashboard' },
  { pathname: '/issues', label: 'Issues' },
  { pathname: '/notifications', label: 'Notifications' },
  { pathname: '/features', label: 'Features' }
];

const Sidebar: FC<Props> = () => {
  const { pathname: currentPathname } = useLocation();
  const title =
    chain(sidebarItems)
      .find(({ pathname }) => pathname === currentPathname)
      .get('label')
      .value() || 'Not Found';

  return (
    <div className={styles.sidebar}>
      <Helmet>
        <title>Web Deploy : {title}</title>
      </Helmet>
      <div className={styles.inner}>
        {map(sidebarItems, ({ pathname, label }) => {
          return <SidebarItem key={pathname} label={label} pathname={pathname} isActivated={pathname === currentPathname} />;
        })}
      </div>
    </div>
  );
};

export default Sidebar;
