import { gql } from 'apollo-boost';

export const issuesQuery = gql`
  query Issues($attributes: IssueAttributes!) {
    issues(attributes: $attributes) {
      total
      results {
        id
        key
        summary
        inReview
        description
        updatedAt
        completedAt
      }
    }
  }
`;

export const createIssueQuery = gql`
  mutation CreateIssue($data: NewIssue!) {
    createIssue(data: $data) {
      id
      key
      summary
      description
      updatedAt
      completedAt
    }
  }
`;
