import React, { FC, memo, ReactNode, useEffect } from 'react';
import styles from './Dashboard.module.scss';
import Typography from '../Typography/Typography';
import { useDispatch } from 'react-redux';
import { DashboardActions } from '../../store/dashboard';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { DateTime } from 'luxon';
import { get, groupBy, map } from 'lodash';
import DayItem from '../DayItem/DayItem';

interface Props {}

const Dashboard: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const { dashboard } = useTypedSelector(({ dashboard: { dashboard } }) => {
    return {
      dashboard
    };
  });

  useEffect(() => {
    dispatch(DashboardActions.getDashboards());
  }, [dispatch]);

  const groupByDateDashboardMap = groupBy(
    map(dashboard, ({ date, featureCount, notificationCount }) => {
      return {
        date: DateTime.fromISO(date).toFormat('yyyyMMdd'),
        featureCount,
        notificationCount
      };
    }),
    'date'
  );

  const today = DateTime.local();
  const dayItemGroups: Array<Array<ReactNode>> = [];

  for (let i = today.weeksInWeekYear, index = 0; i >= 0; i--, index++) {
    const week = today.minus({ week: i }).plus({ day: today.weekday === 7 ? 6 : 7 - today.weekday - 1 });
    dayItemGroups[index] = [];

    for (let j = 6; j >= 0; j--) {
      const day = week.minus({ day: j });
      const value = get(groupByDateDashboardMap, [day.toFormat('yyyyMMdd'), 0]);

      const featureCount = get(value, 'featureCount');
      const notificationCount = get(value, 'notificationCount');

      dayItemGroups[index].push(
        <DayItem
          key={`${i}-${j}`}
          dateTime={day}
          percent={(featureCount + notificationCount) / 15}
          isAfterToday={today.diff(day).milliseconds < 0}
          featureCount={featureCount}
          notificationCount={notificationCount}
        />
      );
    }
  }

  return (
    <div className={styles.dashboard}>
      <Typography variant="h2" className={styles.title}>
        Dashboard
      </Typography>
      <div className={styles.groups}>
        {map(dayItemGroups, (group, key) => {
          return (
            <div key={key} className={styles.group}>
              {group}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default Dashboard;
