import ApolloClient from 'apollo-boost';

export const client = new ApolloClient({
  uri: 'https://web-deploy.api.heydealer.com/graphql',
  request: async operation => {
    const token = localStorage.getItem('token');

    if (token) {
      operation.setContext({
        headers: {
          'Authorization': `JWT ${token}`,
        }
      });
    }
  },
});
