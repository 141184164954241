import React, { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Issues.module.scss';
import Typography from '../Typography/Typography';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { IssueActions } from '../../store/issue';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { filter, chain } from 'lodash';
import { serializeIssues } from '../../cores/serializeIssues';
import IssueItem from '../IssueItem/IssueItem';
import Button from '../Button/Button';
import { useToast } from '../../hooks/useToast';
import { Portal } from 'react-portal';
import IssueForm from '../IssueForm/IssueForm';

interface Props {}

const workday = 7;

const Issues: FC<Props> = memo(() => {
  const [offset, setOffset] = useState(0);
  const toast = useToast();
  const dispatch = useDispatch();
  const [toggleNewIssuePopup, setToggleNewIssuePopup] = useState(false);

  const today = useMemo(() => {
    return DateTime.local();
  }, []);

  const offsetDays = useMemo(() => {
    return offset * 7;
  }, [offset]);

  const endAt = useMemo(() => {
    return today
      .plus({ day: 7 - today.weekday })
      .plus({ day: offsetDays })
      .set({ hour: 0, second: 0, minute: 0, millisecond: 0 });
  }, [today, offsetDays]);

  const startAt = useMemo(() => {
    return today
      .minus({ day: today.weekday - 1 })
      .plus({ day: offsetDays })
      .set({ hour: 0, second: 0, minute: 0, millisecond: 0 });
  }, [today, offsetDays]);

  useEffect(() => {
    dispatch(IssueActions.getIssues(startAt, endAt));
  }, [dispatch, endAt, startAt]);

  const issues = useTypedSelector(({ issue: { issues } }) => issues);
  issues.sort((a, b) => {
    if (a.completedAt === null && b.completedAt === null) {
      return 0;
    } else if (a.completedAt === null || b.completedAt === null) {
      return 1;
    } else {
      return 2;
    }
  });

  const startAtDisplay = useMemo(() => {
    return startAt.toFormat('yyyy년 M월 d일');
  }, [startAt]);

  const endAtDisplay = useMemo(() => {
    return endAt.toFormat('yyyy년 M월 d일');
  }, [endAt]);

  const groups = useMemo(() => {
    return serializeIssues(issues);
  }, [issues]);

  const onExport = useCallback(() => {
    const textarea = document.createElement('textarea');
    textarea.value = chain(groups)
      .map(
        group =>
          group.name +
          '\n' +
          chain(group.values)
            .map(value => `- ${value}`)
            .join('\n')
            .value()
      )
      .join('\n\n')
      .value();

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    toast.success('복사되었습니다.');
  }, [groups, toast]);

  const onToggleNewIssuePopup = useCallback(() => {
    setToggleNewIssuePopup(prevToggleNewIssuePopup => !prevToggleNewIssuePopup);
  }, []);

  const onCloseIssueFormPopup = useCallback(() => {
    setToggleNewIssuePopup(false);
  }, []);

  const weeks = useMemo(() => {
    const days: Array<ReactNode> = [];

    for (let i = 0; i < workday; i++) {
      const date = startAt.plus({ day: i });
      const currentDateIssues = filter(issues, ({ updatedAt }) => DateTime.fromISO(updatedAt).weekday === i + 1);

      days[i] = (
        <div key={i} className={styles.week}>
          <p className={styles.weekday}>{date.weekdayLong}</p>
          <div className={styles.issues}>
            {chain(currentDateIssues)
              .sort((a, b) => {
                if (a.completedAt === null) {
                  return -1;
                }

                const aKey = parseInt(a.key.replace(/[^\d]+(\d+)$/g, '$1'));
                const bKey = parseInt(b.key.replace(/[^\d]+(\d+)$/g, '$1'));

                return aKey < bKey ? 1 : aKey > bKey ? -1 : 0;
              })
              .map((issue, key2) => {
                return <IssueItem key={issue.id} issue={issue} zIndex={key2 + i} />;
              })
              .value()}
          </div>
        </div>
      );
    }

    return days;
  }, [startAt, issues]);

  const onPrevOffset = useCallback(() => {
    setOffset(prevState => prevState - 1);
  }, []);

  const onNextOffset = useCallback(() => {
    setOffset(prevState => prevState + 1);
  }, []);

  return (
    <div className={styles.issues}>
      <Typography variant="h2" className={styles.title}>
        Issues
      </Typography>
      <div className={styles.actions}>
        <Button variant="outline" onClick={onToggleNewIssuePopup}>
          New
        </Button>
        <Button variant="outline" onClick={onExport}>
          Export
        </Button>
      </div>
      <div className={styles.kanbanBoard}>
        <div className={styles.date}>
          <Button onClick={onPrevOffset}>Prev</Button>
          <div className={styles.range}>
            {startAtDisplay} ~ {endAtDisplay}
          </div>
          <Button onClick={onNextOffset}>Next</Button>
        </div>
        <div className={styles.weeks}>{weeks}</div>
      </div>
      {toggleNewIssuePopup && (
        <Portal>
          <div className={styles.blind} />
          <IssueForm onClose={onCloseIssueFormPopup} />
        </Portal>
      )}
    </div>
  );
});

export default Issues;
