import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Dashboard } from '../declaration/graphql';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { client } from '../cores/client';
import { dashboardQuery } from '../queries/dashboard';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';

export enum DashboardTypes {
  getDashboards = '@dashboard/getDashboards'
}

export interface DashboardState {
  dashboard: Array<Dashboard>;
}

export const DashboardActions = {
  getDashboards: createAsyncSagaAction(DashboardTypes.getDashboards, () => {
    return client.query({ query: dashboardQuery, fetchPolicy: 'no-cache' });
  })
};

const initialState: DashboardState = {
  dashboard: []
};

export default handleActions<DashboardState, any>(
  {
    ...createAsyncSagaReducerMap(DashboardTypes.getDashboards, {
      onSuccess: (state, action: PayloadAction<string, { data: { dashboard: Array<Dashboard> } }>) => {
        return produce(state, draft => {
          draft.dashboard = action.payload.data.dashboard;
        });
      }
    })
  },
  initialState
);
