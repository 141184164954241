import React, { FC } from 'react';
import styles from './SidebarItem.module.scss';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Ink from 'react-ink';

interface Props {
  label: string;
  pathname: string;
  isActivated: boolean;
}

const SidebarItem: FC<Props> = ({ label, pathname, isActivated }) => {
  return (
    <Link to={pathname} className={classNames(styles.sidebarItem, isActivated && styles.isActivated)}>
      <Typography variant="p" className={styles.typography}>
        {label}
      </Typography>
      <Ink style={{ color: '#fff' }} />
    </Link>
  );
};

export default SidebarItem;
