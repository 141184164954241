import produce from 'immer';
import { handleActions } from 'redux-actions';
import { Feature } from '../declaration/graphql';
import { client } from '../cores/client';
import { featuresQuery, deleteFeatureQuery } from '../queries/feature';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

export enum FeatureTypes {
  getFeatures = '@feature/getFeatures',
  deleteFeature = '@feature/deleteFeature'
}

export interface FeatureState {
  features: Array<Feature>;
  deleteFeatureMap: { [key: string]: boolean };
}

export const FeatureActions = {
  getFeatures: createAsyncSagaAction(FeatureTypes.getFeatures, () => {
    return client.query({ query: featuresQuery, fetchPolicy: 'no-cache' });
  }),
  deleteFeature: createAsyncSagaAction(FeatureTypes.deleteFeature, (id: number) => {
    return client.mutate({ mutation: deleteFeatureQuery, variables: { data: { id } } });
  })
};

const initialState: FeatureState = {
  features: [],
  deleteFeatureMap: {}
};

export default handleActions<FeatureState, any>(
  {
    ...createAsyncSagaReducerMap(FeatureTypes.getFeatures, {
      onSuccess: (state, action: PayloadAction<string, { data: { features: Array<Feature> } }>) => {
        return produce(state, draft => {
          draft.features = action.payload.data.features;
        });
      }
    }),
    ...createAsyncSagaReducerMap(FeatureTypes.deleteFeature, {
      onSuccess: (state, action: PayloadAction<string, { data: { deleteFeature: number | null } }>) => {
        if (action.payload.data.deleteFeature === null) {
          return state;
        }

        return produce(state, draft => {
          draft.deleteFeatureMap[action.payload.data.deleteFeature as number] = true;
        });
      }
    })
  },
  initialState
);
