import { gql } from 'apollo-boost';

export const dashboardQuery = gql`
  query {
    dashboard {
      date
      featureCount
      notificationCount
    }
  }
`;
