import { all } from 'redux-saga/effects';
import user from './user';
import issue from './issue';
import toast from './toast';
import loading from './loading';
import feature from './feature';
import dashboard from './dashboard';
import notification from './notification';

export default function* rootSaga() {
  yield all([
    user(),
    toast(),
    issue(),
    loading(),
    feature(),
    dashboard(),
    notification()
  ]);
}
