import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import styles from './Notifications.module.scss';
import { useDispatch } from 'react-redux';
import { NotificationActions, NotificationTypes } from '../../store/notification';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Typography from '../Typography/Typography';
import NotificationItem from '../NotificationItem/NotificationItem';
import { get, map, groupBy, chain } from 'lodash';
import { usePagination } from '../../hooks/usePagination';

interface Props {}

const Notifications: FC<Props> = memo(() => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { loading, notifications, stopPagination } = useTypedSelector(
    ({ notification: { stopPagination, notifications }, loading: { asyncMap } }) => {
      return {
        loading: get(asyncMap, NotificationTypes.getNotifications) > 0,
        notifications,
        stopPagination
      };
    }
  );

  const onNextPage = useCallback(() => {
    if (loading || stopPagination) {
      return;
    }

    setPage(prevPage => prevPage + 1);
  }, [loading, stopPagination]);

  useEffect(() => {
    dispatch(NotificationActions.getNotifications(page));
  }, [dispatch, page]);

  usePagination(onNextPage);

  const groupByRepository = groupBy(notifications, 'repository');

  return (
    <div className={styles.notifications}>
      <Typography variant="h2" className={styles.title}>
        Notifications
      </Typography>
      <div className={styles.list}>
        {map(notifications, notification => {
          const index = chain(groupByRepository)
            .get(notification.repository)
            .findIndex(({ id }) => id === notification.id)
            .value();
          const prevNotificationSize =
            chain(groupByRepository)
              .get(notification.repository)
              .get(index + 1)
              .get('size')
              .value() || undefined;
          return <NotificationItem key={notification.id} {...notification} prevNotificationSize={prevNotificationSize} />;
        })}
      </div>
    </div>
  );
});

export default Notifications;
