import React, { FC, useCallback } from 'react';
import styles from './Header.module.scss';
import Button from '../Button/Button';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/user';

interface Props {

}

const Header: FC<Props> = () => {
  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    dispatch(UserActions.logout());
  }, [dispatch]);

  return (
    <div className={styles.header}>
      <Button className={styles.logout} onClick={onLogout}>
        Logout
      </Button>
    </div>
  );
};

export default Header;
