import { gql } from 'apollo-boost';

export const featuresQuery = gql`
  query {
    features {
      id
      name
      createdAt
    }
  }
`;

export const deleteFeatureQuery = gql`
  mutation DeleteFeature($data: DeleteFeature!) {
    deleteFeature(data: $data)
  }
`;
