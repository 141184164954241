import { combineReducers } from 'redux';
import user, { UserState } from './user';
import toast, { ToastState } from './toast';
import issue, { IssueState } from './issue';
import loading, { LoadingState } from './loading';
import feature, { FeatureState } from './feature';
import dashboard, { DashboardState } from './dashboard';
import notification, { NotificationState } from './notification';

export default combineReducers<Application>({
  user,
  toast,
  issue,
  feature,
  loading,
  dashboard,
  notification
});

export interface Application {
  user: UserState;
  toast: ToastState;
  issue: IssueState;
  feature: FeatureState;
  loading: LoadingState;
  dashboard: DashboardState;
  notification: NotificationState;
}
